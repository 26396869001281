import Button from './Button';
import Checkbox from './Checkbox';
import Code from './Code';
import FormControl from './FormControl';
import FormLabel from './FormLabel';
import Heading from './Heading';
import Input from './Input';
import Label from './Label';
import Link from './Link';
import Markdown from './Markdown';
import Menu from './Menu';
import Modal from './Modal';
import PackageSearchForm from './PackageSearchForm';
import Popover from './Popover';
import Radio from './Radio';
import Tabs from './Tabs';
import TabTrigger from './TabTrigger';
import Text from './Text';
import Tooltip from './Tooltip';
import TreeLink from './TreeLink';
import UpboundLogoAnchor from './UpboundLogoAnchor';

const components = {
  Button,
  Checkbox,
  Code,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Label,
  Link,
  Markdown,
  Menu,
  Modal,
  Popover,
  Radio,
  PackageSearchForm,
  Tabs,
  TabTrigger,
  Text,
  Tooltip,
  TreeLink,
  UpboundLogoAnchor,
};

export default components;

import {
  ApolloClient,
  ApolloClientOptions,
  createHttpLink,
  from,
  InMemoryCache,
  Operation,
  selectURI,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { ContextSetter, setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';

import upboundIntrospectionResult from '@/graphql/upbound-introspection-result';
import { CONFIG_API_HOST, CONFIG_INTERNAL_GRAPHQL_ENDPOINT } from '@/utils/constants/config';
import { IS_ENV_PRODUCTION } from '@/utils/constants/env';

const contextSetter: ContextSetter = (_, prevContext) => {
  if (prevContext && prevContext.req && prevContext.req.headers && prevContext.req.headers.cookie) {
    return {
      ...prevContext,
      headers: {
        Cookie: prevContext.req.headers.cookie,
      },
    };
  }

  return prevContext;
};

const errorHandler: ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (IS_ENV_PRODUCTION) {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => console.log(`[GraphQL error]: ${message}`));
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
};

/**
 * Instantiate a new Apollo client
 * @returns A new client
 */
function createClient(options?: Omit<ApolloClientOptions<InMemoryCache>, 'cache'>) {
  return new ApolloClient({
    credentials: 'include',
    ...options,
    cache: new InMemoryCache({
      addTypename: true,
      possibleTypes: upboundIntrospectionResult.possibleTypes,
      typePolicies: { Query: { fields: { registry: { merge: true } } } },
    }),
  });
}

/**
 * Not for client-side use!
 */
// TODO(hasheddan): consider using a separate error handler for server-side client.
function ssrClient() {
  return createClient({
    link: from([
      onError(errorHandler),
      setContext(contextSetter),
      createHttpLink({
        uri: `${CONFIG_INTERNAL_GRAPHQL_ENDPOINT}`,
      }),
    ]),
    ssrMode: true,
  });
}

/**
 * Not for server-side use!
 */
const localClient = createClient({
  link: from([
    onError(errorHandler),
    new BatchHttpLink({
      uri: `https://${CONFIG_API_HOST}/graphql`,
      credentials: 'include',
      headers: { 'Upbound-API-Version': 'old' },
      batchMax: 20,
      batchDebounce: true,
      batchKey: (operation: Operation) => {
        const context = operation.getContext();

        const contextConfig = {
          http: context.http,
          options: context.fetchOptions,
          credentials: context.credentials,
          headers: context.headers,
        };

        const key = selectURI(operation, '/graphql') + JSON.stringify(contextConfig);

        if (operation.operationName === 'GetPackageUserSettings') {
          return 'GetPackageUserSettings' + key;
        }

        //may throw error if config not serializable
        return key;
      },
    }),
  ]),
});

export const hasLimitReachedError = (gqlErrors: readonly GraphQLError[]) => {
  return gqlErrors.some(err => {
    const gqlCode = err.extensions && err.extensions.code;

    if (gqlCode && gqlCode === 'LIMIT_REACHED') {
      return true;
    }

    const gqlResponse: Response | false = err.extensions && (err.extensions.response as Response);

    if (gqlResponse && gqlResponse.status.toString() === '435') {
      return true;
    }

    return false;
  });
};

export { ssrClient, localClient };

const colors = {
  yellow: {
    0: '#ffb900', // 0
    1: '#ffb9003b', // 1
    2: '#FFFBEF', // faint yellow
  },
  orange: {
    0: '#ffa34d', // 0 lightOrange
  },
  red: {
    0: '#ff4f52', // 0 lightRed
  },
  green: {
    0: '#00b67a', // 0 lightGreen
    1: '#00333e', // 1 softGreen
  },
  turquoise: {
    0: '#3de2cb', // 0 aquaMarine
    1: '#2fd3bc', // 1 aquaGreen
    2: '#37ccb7', // 2 aquarius
    3: '#43c5ae', // 3 aquaGreenDark
  },
  purple: {
    3: '#bdb5e4', // 3 fadedIris
    4: '#a097ff', // 4
    5: '#8f86ff', // 5
    0: '#6d64f5', // 0 cornflower
    1: '#4845a0', // 1 bMedPurple
    2: '#5445a0', // 2 blueberry
    6: '#6553C0',
  },

  blue: {
    0: '#333F5B', // 0 darkGreyBlue
    3: '#1F3C6A', // 3
    1: '#263B67', // 1 Fill/Action/Primary
    2: '#1A3245', // 2 darkBlue1A3245
    4: '#2196F3',
  },
  white: {
    0: '#ffffff', // 0 white
    1: '#f8fbff', // 1 paleGrey
    2: '#f6faff', // 2 whiteBlue
    9: '#f5f8ff', // 9
    7: '#f4f5f7', // 7 neutralDark6
    8: '#f3f5f8', // 8 neutralDark7
    11: '#fafafa',
    3: '#e5eaf4', // 3 veryLightBlue
    4: '#dce0e6', // 4 paleBlue
    5: '#dcdcde', // 5 lightPeriwinkle
    6: '#c3c4c6', // 6 cloudyBlue
    10: '#fafbfb00', // 10
    12: '#DEE0E0', // 12 light grey
  },
  grey: {
    2: '#939EAB', // 2 blueyGrey
    4: '#7C8496', // 4
    0: '#505A72', // 0 slate
    1: '#929394', // 1 blueGrey
    3: '#828282', // 3 darkBlueGrey
    5: '#F8F9F9', // 5
    6: '#686A7C', // 6
    7: '#F2F2F4',
    8: '#E5E6E9',
  },
  black: {
    1: '#5A7184', // 1 fillBlackGray
    3: '#043C58', // 3 fillBlackBlue
    0: '#183B56', // 0 fillBlackBlack
    2: '#0D2436', // 2 darkBlueGreyTwo
  },
};

export default colors;

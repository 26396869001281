import type { ComponentStyleConfig } from '@chakra-ui/theme';

const FormControl: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '900',
    color: 'black.0',
  },
  sizes: {
    // h1
    xl: {
      fontSize: '55px',
      lineHeight: '70px',
    },
    // h2
    lg: {
      fontSize: '40px',
      lineHeight: '50px',
    },
    // h3
    md: {
      fontSize: '25px',
      lineHeight: '35px',
    },
    // h4
    sm: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    // h5
    xs: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
};

export default FormControl;

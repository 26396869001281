import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { GetAllAccountsQuery, useGetAllAccountsLazyQuery } from '@/graphql/upbound-graphql';

export type AccountsState = {
  accounts: GetAllAccountsQuery['accounts'];
  fetchStage: 'unloaded' | 'loading' | 'failed' | 'succeeded';
};

const initialState: AccountsState = { accounts: [], fetchStage: 'unloaded' };

const AccountsContext = createContext(initialState);

export const AccountsProvider: React.FC<PropsWithChildren> = props => {
  const [state, setState] = useState<AccountsState>({ accounts: [], fetchStage: 'unloaded' });
  const [getAccountsQuery] = useGetAllAccountsLazyQuery({ pollInterval: 300000 }); // Poll 5 min to prevent timeouts

  useEffect(() => {
    (async () => {
      setState({ accounts: [], fetchStage: 'loading' });
      try {
        const { data, error } = await getAccountsQuery();

        if (!data || error) {
          setState({ accounts: [], fetchStage: 'failed' });
          return;
        }

        setState({ accounts: data.accounts, fetchStage: 'succeeded' });
      } catch (err) {
        console.log('error', JSON.stringify(err));
        setState({ accounts: [], fetchStage: 'failed' });
      }
    })();
  }, [getAccountsQuery]);

  return <AccountsContext.Provider value={state} {...props} />;
};

export function useAccounts() {
  const context = useContext(AccountsContext);

  if (!context) {
    throw new Error('useAccounts must be used within an AccountsProvider');
  }

  return context;
}

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const PackageSearchForm: ComponentStyleConfig = {
  parts: ['form', 'input', 'icon', 'clear'],
  baseStyle: {
    input: {
      background: 'white.0',
      backgroundColor: 'white.0',
      fontSize: '14px',
      lineHeight: '20px',
      boxSizing: 'border-box',
      outline: 'none',
      cursor: 'text',
      color: 'grey.0',
      border: '1px solid',
      borderColor: 'white.5',
      borderRadius: '25px',
      width: '100%',
      flexGrow: '1',
      _placeholder: {
        opacity: '1',
        color: 'grey.1',
      },
      _disabled: {
        backgroundColor: 'white.1',
        color: 'grey.1',
      },
      _readOnly: {
        backgroundColor: 'white.1',
        color: 'grey.1',
      },
      _focus: {
        background: 'white.0',
        backgroundColor: 'white.0',
        boxShadow: 'none',
      },
      '&&': {
        height: 'unset',
      },
    },
    icon: {
      position: 'absolute',
      width: '14px',
      height: '14px',
      zIndex: 10,
    },
    clear: {
      width: '14px',
      height: '14px',
      color: 'grey.4',
      position: 'absolute',
      display: 'flex',
      right: '18px',
      top: '14px',
      zIndex: 10,
      _hover: {
        cursor: 'pointer',
        color: 'grey.0',
      },
    },
  },
  sizes: {
    small: {
      input: {
        padding: '7px 18px 6px 40px',
        paddingInlineStart: '40px',
        paddingInlineEnd: '18px',
        fontSize: '14px',
        lineHeight: '25px',
      },
      icon: {
        color: 'grey.0',
        left: '18px',
        top: '14px',
      },
      clear: {},
    },
    large: {
      input: {
        padding: '12px 32px 12px 53px',
        paddingInlineStart: '53px',
        paddingInlineEnd: '32px',
        fontSize: '16px',
        lineHeight: '25px',
      },
      icon: {
        color: 'black.3',
        left: '32px',
        top: '18px',
      },
      clear: {},
    },
  },
  variants: {
    mobile: {
      form: {
        width: '100%',
        p: '0 22px 30px 22px',
        backgroundColor: 'blue.1',
        position: 'relative',
      },
      clear: {
        right: '40px',
      },
      icon: {
        left: '40px',
      },
    },
    desktop: {
      form: {
        maxWidth: '300px',
        m: '0 auto',
      },
    },
  },
};

export default PackageSearchForm;

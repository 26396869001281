const styles = {
  global: {
    '*': {
      boxSizing: 'border-box',
      outline: 'none',
      appearance: 'none',
      boxShadow: 'none',
      ':focus-visible, :focus': {
        outline: 'none',
        outlineWidth: '0px',
        outlineStyle: 'none',
      },
    },
    body: {
      fontWeight: '400',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      overflowX: 'hidden',
    },
    'html, body, #__next': {
      height: '100%',
      width: '100%',
    },
    'html > body > div#hs-eu-cookie-confirmation': {
      backgroundColor: 'grey.0',
      boxShadow: 'none',
      borderTopWidth: '0',
      zIndex: '2147483647',
      '& > div#hs-eu-cookie-confirmation-inner': {
        backgroundColor: 'grey.0',
        padding: '30px 40px',
        '& > p#hs-eu-cookie-disclaimer, & > div#hs-eu-policy-wording > p, & > div#hs-eu-policy-wording > p > span': {
          color: 'white.0',
          fontSize: '14px',
          lineHeight: '24px',
        },
        '& > div#hs-en-cookie-confirmation-buttons-area > div#hs-eu-cookie-confirmation-button-group > a': {
          fontSize: '14px',
          lineHeight: '34px',
          padding: '0 20px',
          borderColor: 'transparent',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '20px',
          cursor: 'pointer',
          outline: 'none',
          overflow: 'hidden',
          boxSizing: 'border-box',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          transition: 'all 0.2s ease-in-out, background-color 0.1s ease-out',
          width: 'auto',
          '&#hs-eu-confirmation-button': {
            backgroundColor: 'turquoise.0',
            color: 'white.0',
            fill: 'white.0',
            '&:hover': {
              borderColor: 'transparent',
              backgroundColor: 'turquoise.2',
              color: 'white.0',
              fill: 'white.0',
            },
          },
          '&#hs-eu-decline-button': {
            borderColor: 'white.0',
            color: 'white.0',
            fill: 'white.0',
            '&:hover': {
              borderColor: 'white.0',
              backgroundColor: 'white.0',
              color: 'black.0',
              fill: 'black.0',
            },
          },
        },
      },
    },
    '#nprogress': {
      pointerEvents: 'none',
    },
    '#nprogress .bar': {
      background: '#29d',
      position: 'fixed',
      zIndex: 1031,
      top: 0,
      left: 0,
      width: '100%',
      height: '2px',
    },
    '#nprogress .peg': {
      display: 'block',
      position: 'absolute',
      right: '0px',
      width: '100px',
      height: '100%',
      boxShadow: '0 0 10px #29d, 0 0 5px #29d',
      opacity: 1.0,
      transform: 'rotate(3deg) translate(0px, -4px)',
    },
  },
};

export default styles;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: 'black.0',
  },
  sizes: {
    sm: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    lg: {
      fontSize: '16px',
      lineHeight: '30px',
    },
    xl: {
      fontSize: '18px',
      lineHeight: '40px',
    },
  },
};

export default Text;

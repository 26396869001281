import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Radio: ComponentStyleConfig = {
  baseStyle: {
    control: {
      border: '1px solid',
      backgroundColor: 'white.0',
      borderColor: 'white.5',
      appearance: 'none',
      outline: 'none',
      margin: '0',
      padding: '0',
      borderRadius: '50%',
      cursor: 'pointer',
      transition: 'border-color 0.1s ease-in-out, background-color 0.1s ease-in-out',
      '&&&': {
        width: '15px',
        height: '15px',
        _before: {
          width: '7px',
          height: '7px',
        },
      },
      _checked: {
        background: 'blue.3',
        borderColor: 'blue.3',
      },
      _disabled: {
        cursor: 'not-allowed',
      },
    },
  },
};

export default Radio;

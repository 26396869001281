import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '12px',
    lineHeight: '20px',
    opacity: '0.95',
    padding: '6px 15px',
    borderRadius: '5px',
    maxWidth: '330px',
    color: 'white.0',
    backgroundColor: 'grey.0',
  },
};

export default Tooltip;

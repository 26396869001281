import type { ComponentStyleConfig } from '@chakra-ui/theme';

const UpboundLogoAnchor: ComponentStyleConfig = {
  parts: ['icon', 'marketplace'],
  baseStyle: {
    icon: {},
    marketplace: {},
  },
  variants: {
    black: {
      icon: {
        color: 'black.0',
      },
      marketplace: {
        color: 'black.0',
      },
    },
    purple: {
      icon: {
        color: 'white.0',
      },
      marketplace: {
        color: 'purple.4',
      },
    },
  },
  sizes: {
    sm: {
      icon: {
        width: '85px',
        height: '22px',
        lineHeight: '22px',
      },
      marketplace: {
        fontSize: '15px',
        lineHeight: '19px',
        marginLeft: '6px',
      },
    },
    md: {
      icon: {
        width: '103px',
        height: '25px',
        lineHeight: '25px',
      },
      marketplace: {
        fontSize: '18px',
        lineHeight: '23px',
        marginLeft: '8px',
      },
    },
    lg: {
      icon: {
        width: '117px',
        height: '29px',
        lineHeight: '29px',
      },
      marketplace: {
        fontSize: '20px',
        lineHeight: '25px',
        marginLeft: '8px',
      },
    },
  },
};

export default UpboundLogoAnchor;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Markdown: ComponentStyleConfig = {
  baseStyle: {
    a: {
      textDecoration: 'underline',
      _hover: {
        textDecoration: 'none',
      },
    },
    p: {
      fontSize: '14px',
      lineHeight: '25px',
      color: 'black.0',
      whiteSpace: 'normal',
      '&:not(&:last-child)': {
        marginBottom: '15px',
      },
    },
    'h1, h2, h3, h4, h5, h6': {
      fontWeight: '900',
      color: 'black.0',
    },
    h1: {
      fontSize: '25px',
      lineHeight: '35px',
      mb: '40px',
      mt: '30px',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '30px',
      mb: '25px',
      mt: '60px',
    },
    h3: {
      fontSize: '16px',
      lineHeight: '25px',
      mb: '20px',
      mt: '40px',
    },
    'p > code': {
      borderRadius: '2px',
      fontFamily: 'Consolas',
      fontSize: '12px',
      lineHeight: '20px',
      color: 'black.0',
      backgroundColor: 'white.8',
      p: '7px 5px 6px 6px',
    },
    pre: {
      padding: '26px 27px',
      m: '30px 0',
      borderRadius: '2px',
      backgroundColor: 'white.8',
      border: '1px solid',
      borderColor: 'white.4',
      overflow: 'auto',
      code: {
        'span.token': {
          fontSize: '12px',
          lineHeight: '20px',

          '&.punctuation, &.key': {
            color: 'black.3',
          },

          '&.boolean': {
            color: 'turquoise.2',
          },

          '&.number, &.string': {
            color: 'purple.1',
          },
        },
        '& > span.line-numbers-rows': {
          left: '-50px',
          width: '50px',
          borderRight: 'none',
          paddingLeft: '30px',

          '& > span': {
            fontSize: '12px',
            lineHeight: '20px',

            '&::before': {
              textAlign: 'left',
            },
          },
        },
      },
    },
    'ul, ol': {
      listStylePosition: 'inside',
      margin: '24px 0',
    },
    li: {
      margin: '12px',
    },
    img: {
      margin: '36px auto',
    },
  },
};

export default Markdown;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const TreeLink: ComponentStyleConfig = {
  baseStyle: {
    display: 'block',
    borderTop: '1px solid',
    borderTopColor: 'white.4',
    background: 'white.0',
    _hover: { color: 'purple.0', svg: { color: 'purple.0' } },
  },
  variants: {
    root: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'black.0',
      p: '16px 25px 16px 24px',
    },
    child: {
      fontSize: '13px',
      lineHeight: '20px',
      color: 'black.1',
      p: '12px 25px 12px 24px',
      ml: '14px',
      _first: {
        ml: '0',
        pl: '38px',
      },
    },
  },
};

export default TreeLink;

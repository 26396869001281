import type { ComponentStyleConfig } from '@chakra-ui/theme';

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    '& + label': {
      marginTop: '13px',
    },
    display: 'flex',
    alignItems: 'center',
    _focus: {
      '& > label > span': {
        background: 'blue.3',
        borderColor: 'blue.3',
        '&::before': {
          content: '""',
          display: 'inline-block',
          position: 'relative',
          width: '50%',
          height: '50%',
          borderRadius: '50%',
          background: 'currentColor',
        },
      },
    },
    _hover: {
      cursor: 'pointer',
      '& > label > span': {
        background: 'blue.3',
        borderColor: 'blue.3',
        color: 'white.0',
        '&::before': {
          content: '""',
          display: 'inline-block',
          position: 'relative',
          width: '50%',
          height: '50%',
          borderRadius: '50%',
          background: 'currentColor',
        },
      },
    },
  },
};

export default FormLabel;

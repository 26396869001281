import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';

import { GetAllOrgAccountsQuery, useGetAllOrgAccountsLazyQuery } from '@/graphql/upbound-graphql';

export type OrgAccount = Extract<
  NonNullable<GetAllOrgAccountsQuery['accounts']>[number],
  { __typename?: 'OrgAccount' }
>;

export type OrgState = {
  orgs: OrgAccount[];
  fetchStage: 'unloaded' | 'loading' | 'failed' | 'succeeded';
};

export type CurrentOrg = OrgAccount | null;

type OrgsContextProps = {
  orgState: OrgState;
  currentOrg: CurrentOrg;
  setCurrentOrg: Dispatch<SetStateAction<CurrentOrg>>;
};

const initialState: OrgsContextProps = {
  orgState: {
    orgs: [],
    fetchStage: 'unloaded',
  },
  currentOrg: null,
  setCurrentOrg: () => undefined,
};
const OrgsContext = createContext(initialState);

// TODO: Remove AccountsProvider once we migrate all users to DevEx redesign

export const OrgsProvider: React.FC<PropsWithChildren> = props => {
  const [orgState, setOrgState] = useState<OrgState>({ orgs: [], fetchStage: 'unloaded' });
  const [currentOrg, setCurrentOrg] = useState<CurrentOrg>(null);

  useEffect(() => {
    if (currentOrg) {
      localStorage.setItem('currentOrg', currentOrg.organization.name);
    }
  }, [currentOrg]);

  const [getOrgAccountsQuery] = useGetAllOrgAccountsLazyQuery({ pollInterval: 300000 });

  useEffect(() => {
    (async () => {
      setOrgState({ orgs: [], fetchStage: 'loading' });
      try {
        const { data, error } = await getOrgAccountsQuery();

        const orgAccounts = data?.accounts.filter((a): a is OrgAccount => a.__typename === 'OrgAccount') || [];

        if (!data || error) {
          setOrgState({ orgs: [], fetchStage: 'failed' });
          return;
        }

        setOrgState({ orgs: orgAccounts, fetchStage: 'succeeded' });

        const cachedOrg = orgAccounts.find(
          orgAccount => orgAccount.organization.name === localStorage.getItem('currentOrg'),
        );

        if (cachedOrg) {
          setCurrentOrg(cachedOrg);
        } else {
          setCurrentOrg(orgAccounts[0] ?? null);
        }
      } catch (err) {
        console.log('error', JSON.stringify(err));
        setOrgState({ orgs: [], fetchStage: 'failed' });
      }
    })();
  }, [getOrgAccountsQuery]);

  return <OrgsContext.Provider value={{ orgState, currentOrg, setCurrentOrg }} {...props} />;
};

export function useOrgs() {
  const context = useContext(OrgsContext);

  if (!context) {
    throw new Error('useOrgs must be used within an OrgsProvider');
  }

  return context;
}

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tab: {
      '&&': {
        a: {
          borderBottomColor: 'white.4',
        },
      },
    },
    tablist: {
      '&&': {
        borderBottom: '1px solid',
        borderBottomColor: 'white.4',
        background: 'white.0',
      },
    },
    tabpanel: {
      '&&': {
        borderBottomColor: 'white.4',
      },
    },
  },
};

export default Tabs;

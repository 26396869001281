import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useEffect, useRef } from 'react';

/**
 * Disable smooth scrolling when the route changes and reenable it when it's done
 */
function usePageTransitionEffect() {
  const routerRef = useRef(useRouter());

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      document.documentElement.style.scrollBehavior = 'auto';
      NProgress.start();
    };

    const handleRouteChangeStop = () => {
      document.documentElement.style.scrollBehavior = 'smooth';
      NProgress.done();
    };

    const router = routerRef.current;

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeStop);
    router.events.on('routeChangeError', handleRouteChangeStop);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeStop);
      router.events.off('routeChangeError', handleRouteChangeStop);
    };
  }, []);
}

export default usePageTransitionEffect;

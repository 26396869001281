import type { ComponentStyleConfig } from '@chakra-ui/theme';

const TabTrigger: ComponentStyleConfig = {
  baseStyle: {
    a: {
      color: 'black.0',
      cursor: 'pointer',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '25px',
      display: 'inline-block',
    },
    margin: '0',
    padding: '0',
    marginRight: '40px',
    _last: {
      marginRight: '0',
    },
    _hover: {
      'a, svg': {
        color: 'purple.0',
      },
    },
    _active: {
      background: 'transparent',
    },
    _selected: {
      'a, svg': {
        color: 'purple.0',
      },
    },
  },
  variants: {
    mobile: {
      '&': {
        textAlign: 'left',
        width: '100%',
        display: 'block',
        p: '10px 20px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
      },
    },
    desktop: {
      '&': {
        fontWeight: 900,
        fontSize: '20px',
        lineHeight: '25px',
        display: 'inline-block',
      },
    },
  },
};

export default TabTrigger;

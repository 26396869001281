/* eslint-disable @typescript-eslint/no-non-null-assertion */
// We are promising to set these all to values (using the .env* mechanism)
export const CONFIG_REGISTRY_HOST = process.env.NEXT_PUBLIC_REGISTRY_HOST!;
export const CONFIG_MARKETING_HOST = process.env.NEXT_PUBLIC_MARKETING_HOST!;
export const CONFIG_CONSOLE_HOST = process.env.NEXT_PUBLIC_CONSOLE_HOST!;
export const CONFIG_API_HOST = process.env.NEXT_PUBLIC_API_HOST!;
export const CONFIG_DOCS_HOST = process.env.NEXT_PUBLIC_DOCS_HOST!;
export const CONFIG_INTERNAL_GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_INTERNAL_GRAPHQL_ENDPOINT!;
export const CONFIG_GTM_ID = process.env.NEXT_PUBLIC_GTM_ID!;
export const CONFIG_INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID!;
export const CONFIG_INTERCOM_API_BASE = process.env.NEXT_PUBLIC_INTERCOM_API_BASE!;
export const CONFIG_HOST_NAME = process.env.NEXT_PUBLIC_HOST_NAME!;
export const CONFIG_NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV!;
export const CONFIG_SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT!;
export const CONFIG_VERSION = process.env.NEXT_PUBLIC_VERSION;
export const CONFIG_RUN_IN_UPBOUND_CONFIGURATION_IDS = process.env.NEXT_PUBLIC_RUN_IN_UPBOUND_CONFIGURATION_IDS!;
export const CONFIG_ACCOUNTS_HOST = process.env.NEXT_PUBLIC_ACCOUNTS_HOST!;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Button: ComponentStyleConfig = {
  baseStyle: {
    backgroundColor: 'white.0',
    borderColor: 'purple.0',
    fontFamily: 'inherit',
    fontStyle: 'inherit',
    fontStretch: 'inherit',
    letterSpacing: 'inherit',
    borderRadius: '20px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '20px',
    outline: 'none',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    transition:
      'background-color 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out, box-shadow 0.2s ease-out, ',
    whiteSpace: 'nowrap',
    width: 'auto',
    minWidth: 'unset',
    height: 'unset',
    _disabled: {
      cursor: 'not-allowed',
    },
  },
  sizes: {
    none: {
      padding: '0',
    },
    sm: {
      '&&&': {
        height: 'unset',
        padding: '6px 13px',
      },
    },
    md: {
      '&&&': {
        padding: '8px 20px',
      },
    },
    lg: {
      '&&&': {
        padding: '14px 45px',
        borderRadius: '24px',
      },
    },
  },
  variants: {
    whiteOutline: {
      backgroundColor: 'transparent',
      borderWidth: '2px',
      borderColor: 'white.0',
      color: 'white.0',
      _hover: {
        textDecoration: 'none',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          backgroundColor: 'transparent',
          color: 'white.0',
        },
      },
    },
    paleGreyOutline: {
      backgroundColor: 'transparent',
      borderColor: 'white.4',
      color: 'grey.1',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'white.1',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          color: 'white.6',
          backgroundColor: 'transparent',
          borderColor: 'white.1',
        },
      },
    },
    noOutline: {
      border: 'none',
      display: 'inline-block',
      padding: '0',
      borderRadius: '0',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _disabled: {
        '&&&': {
          opacity: '0.5',
        },
      },
    },
    noStyle: {
      width: 'unset',
      height: 'unset',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      border: 'none',
      display: 'inline-block',
      padding: 0,
      borderRadius: 0,
      color: 'black.0',
      _disabled: {
        '&&&': {
          opacity: 0.5,
        },
      },
      _hover: {
        textDecoration: 'none',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        border: 'none',
        color: 'black.0',
      },
    },
    aquaGreenFill: {
      backgroundColor: 'turquoise.1',
      borderColor: 'turquoise.1',
      borderWidth: '2px',
      color: 'white.0',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'turquoise.3',
        borderColor: 'turquoise.3',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          backgroundColor: 'turquoise.1',
          borderColor: 'turquoise.1',
        },
      },
    },
    aquaMarineFill: {
      backgroundColor: 'turquoise.0',
      borderColor: 'transparent',
      borderWidth: '2px',
      color: 'white.0',
      _hover: {
        textDecoration: 'none',
        borderColor: 'transparent',
        backgroundColor: 'turquoise.2',
        color: 'white.0',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          backgroundColor: 'turquoise.0',
          borderColor: 'transparent',
          color: 'white.0',
        },
      },
    },
    cornflowerFill: {
      backgroundColor: 'purple.0',
      borderColor: 'purple.0',
      borderWidth: '2px',
      color: 'white.0',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'purple.1',
        borderColor: 'purple.1',
        color: 'white.0',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          backgroundColor: 'purple.0',
          borderColor: 'purple.0',
          color: 'white.0',
        },
      },
    },
    blackOutline: {
      backgroundColor: 'transparent',
      borderColor: 'black.0',
      borderWidth: '2px',
      color: 'black.0',
      _hover: {
        textDecoration: 'none',
        borderColor: 'black.0',
        backgroundColor: 'black.0',
        color: 'white.0',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          borderColor: 'grey.2',
          backgroundColor: 'transparent',
          color: 'grey.2',
        },
      },
    },
    blackFill: {
      backgroundColor: 'black.0',
      borderColor: 'black.0',
      borderWidth: '2px',
      color: 'white.0',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'transparent',
        borderColor: 'black.0',
        color: 'black.0',
        boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.2)',
      },
      _disabled: {
        '&&&': {
          borderColor: 'black.1',
          backgroundColor: 'black.1',
          color: 'white.2',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};

export default Button;

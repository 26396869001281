import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      backgroundColor: 'white.0',
      _checked: {
        backgroundColor: 'black.3',
        borderColor: 'black.3',
      },
    },
  },
};

export default Checkbox;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Popover: ComponentStyleConfig = {
  baseStyle: {
    popper: {
      zIndex: '1000',
    },
    content: {
      width: 'unset',
      padding: '0px',
      borderRadius: '10px',
      backgroundColor: 'white.0',
      boxShadow: '0 16px 48px 0 rgba(0, 0, 0, 0.16)',
      transition: 'opacity 0.4s ease-in-out',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
    },
  },
};

export default Popover;

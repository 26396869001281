import type { ComponentStyleConfig } from '@chakra-ui/theme';

// NOTE Update this file with more styles as necessary

const field = {
  background: 'white.0',
  backgroundColor: 'white.0',
  borderRadius: '5px',
  fontSize: '14px',
  lineHeight: '20px',
  boxSizing: 'border-box',
  width: '100%',
  height: 'unset',
  padding: '9px 15px',
  outline: 'none',
  cursor: 'text',
  color: 'grey.0',
  border: '1px solid',
  borderColor: 'white.5',
  _placeholder: {
    opacity: '1',
    color: 'grey.1',
  },
  _disabled: {
    backgroundColor: 'white.1',
    color: 'grey.1',
  },
  _readOnly: {
    backgroundColor: 'white.1',
    color: 'grey.1',
  },
  _focus: {
    background: 'white.0',
    backgroundColor: 'white.0',
    boxShadow: 'none',
  },
};

const Input: ComponentStyleConfig = {
  variants: {
    outline: { field },
    filled: { field },
    flushed: { field },
    unstyled: { field },
  },
  baseStyle: {
    field,
  },
};

export default Input;

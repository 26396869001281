import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Menu: ComponentStyleConfig = {
  baseStyle: {
    button: {
      cursor: 'pointer',
      borderRadius: '5px',
      border: '1px solid',
      borderColor: 'white.5',
      backgroundColor: 'white.0',
      transition: 'all 0.2s ease-in-out 0.2s, border-radius 0.2s ease-in-out 0s',
      padding: '8px 18px',
      '& > span': {
        color: 'grey.0',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        fontSize: '14px',
        lineHeight: '20px',
        '& > svg': {
          color: 'grey.0',
          ml: 'auto',
        },
        '& > span': {
          color: 'grey.0',
        },
      },
      _active: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
    },
    list: {
      '&&&&&&': {
        width: '100%',
        minWidth: 'unset',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: '0 0 5px 5px',
        boxShadow: '0',
        border: '1px solid',
        borderColor: 'white.5',
        borderTop: '0px',
        backgroundColor: 'white.0',
        overflow: 'hidden',
        transition: 'opacity 0.2s ease-in-out, height 0s ease-in-out 0.2s',
        transform: 'none !important',
      },
    },
    item: {
      cursor: 'pointer',
      borderTop: '1px solid',
      borderTopColor: 'white.5',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'grey.0',
      padding: '8px 18px',
      zIndex: 10,
      _active: {
        backgroundColor: 'white.1',
      },
      _focus: {
        backgroundColor: 'white.1',
      },
      _hover: {
        backgroundColor: 'white.1',
      },
      _first: {
        borderTop: '0px',
      },
    },
  },
  sizes: {
    sm: {
      button: {
        padding: '2px 2px 2px 6px',
        '& > span': {
          width: '100%',
          '& > span': {
            fontSize: '12px',
            lineHeight: '20px',
          },
          '& > svg': {
            width: '7px',
            height: '7px',
            lineHeight: '20px',
            marginLeft: '2px',
          },
        },
      },
      list: {
        padding: '0px',
        marginTop: '-8px',
      },
      item: {
        fontSize: '12px',
        lineHeight: '20px',
        padding: '2px 2px 2px 6px',
      },
    },
  },
};

export default Menu;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Code: ComponentStyleConfig = {
  baseStyle: {
    '&&&&&&': {
      fontFamily: 'Consolas',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
      color: 'black.1',
    },
    '& > span.token': {
      fontSize: '12px',
      lineHeight: '20px',

      '&.punctuation, &.key': {
        color: 'black.3',
      },

      '&.boolean': {
        color: 'turquoise.2',
      },

      '&.number, &.string': {
        color: 'purple.1',
      },
    },
    '& > span.line-numbers-rows': {
      left: '-50px',
      width: '50px',
      borderRight: 'none',
      paddingLeft: '30px',

      '& > span': {
        fontSize: '12px',
        lineHeight: '20px',

        '&::before': {
          textAlign: 'left',
        },
      },
    },
  },
};

export default Code;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const FormControl: ComponentStyleConfig = {
  baseStyle: {
    margin: '0px',
    padding: '0px',
  },
};

export default FormControl;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Input: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '4px',
    cursor: 'pointer',
    color: 'grey.0',
  },
  sizes: {
    sm: {
      fontSize: '10px',
    },
  },
  variants: {},
};

export default Input;

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Modal: ComponentStyleConfig = {
  baseStyle: {
    modal: {
      display: 'flex',
      position: 'relative',
      overflow: 'auto',
      padding: '60px 45px',
      margin: '0 auto',
      maxHeight: '80%',
      boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.7)',
      borderRadius: '10px',
      transition: `opacity ease-in-out 0.5s`,
      backgroundColor: 'white.0',
    },
    dialogContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    overlay: {
      backgroundColor: 'rgba(23, 43, 77, 0.45)',
    },
    dialog: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '600px',
      position: 'relative',
      overflow: 'auto',
      padding: '60px 45px',
      margin: '0 auto',
      maxHeight: '80%',
      boxShadow: '0 2px 5px 0 rgba(80, 90, 114, 0.7)',
      borderRadius: '10px',
      transition: `opacity ease-in-out 0.5s`,
      backgroundColor: 'white.0',
    },
  },
};

export default Modal;

import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { GetCurrentUserQuery, useGetCurrentUserLazyQuery } from '@/graphql/upbound-graphql';

export type CurrentUserState = {
  currentUser: GetCurrentUserQuery['currentUser'] | undefined;
  fetchStage: 'unloaded' | 'loading' | 'failed' | 'succeeded';
};

const initialState: CurrentUserState = { currentUser: undefined, fetchStage: 'unloaded' };

const CurrentUserContext = createContext(initialState);

export const CurrentUserProvider: React.FC<PropsWithChildren> = props => {
  const [state, setState] = useState<CurrentUserState>({ currentUser: undefined, fetchStage: 'unloaded' });
  const [getCurrentUserQuery] = useGetCurrentUserLazyQuery({ pollInterval: 300000 }); // Poll 5 min to prevent timeouts

  useEffect(() => {
    (async () => {
      setState({ currentUser: undefined, fetchStage: 'loading' });
      try {
        const { data, error } = await getCurrentUserQuery();

        if (!data || error) {
          setState({ currentUser: undefined, fetchStage: 'failed' });
          return;
        }

        setState({ currentUser: data.currentUser, fetchStage: 'succeeded' });
      } catch (err) {
        console.log('error', JSON.stringify(err));
        setState({ currentUser: undefined, fetchStage: 'failed' });
      }
    })();
  }, [getCurrentUserQuery]);

  return <CurrentUserContext.Provider value={state} {...props} />;
};

export function useCurrentUser() {
  const context = useContext(CurrentUserContext);

  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }

  return context;
}

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Link: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out',
    color: 'black.0',
  },
};

export default Link;

import Script from 'next/script';
import { useEffect } from 'react';

import { useCurrentUser } from '@/contexts/currentUser';
import { CONFIG_INTERCOM_API_BASE, CONFIG_INTERCOM_APP_ID } from '@/utils/constants/config';
import { IS_ENV_PRODUCTION } from '@/utils/constants/env';

const intercomSettings = { app_id: CONFIG_INTERCOM_APP_ID, api_base: CONFIG_INTERCOM_API_BASE };

const isLoadable = IS_ENV_PRODUCTION && CONFIG_INTERCOM_APP_ID;

const IntercomScript: React.FC = () => {
  const { currentUser, fetchStage } = useCurrentUser();

  useEffect(() => {
    if (!isLoadable || !window.Intercom || fetchStage === 'unloaded' || fetchStage === 'loading') {
      return;
    }

    window.Intercom('boot', { ...intercomSettings, ...currentUser });
  }, [currentUser, fetchStage]);

  if (!isLoadable) {
    return null;
  }

  return (
    <Script
      id="intercomScript"
      defer={true}
      async={true}
      src={`https://widget.intercom.io/widget/${CONFIG_INTERCOM_APP_ID}`}
    />
  );
};

export default IntercomScript;
